import { useLayoutEffect, useMemo, useRef } from 'react';
import type { List, Map } from 'immutable';

import {
  compareEdges,
  compareNodes,
  onlyDataUpdated,
  prepareFlowData,
  withDataIfAvailable,
} from '@/modules/flows-v2/builder/helpers';
import type { AppNode } from '@/modules/flows-v2/builder/types';
import { BuilderContext, type BuilderStore, createBuilderStore } from './store';

export const BuilderProvider = ({
  config,
  phases,
  tasks,
  readOnly,
  components,
  configurations,
  deletedComponents,
  tablesMetadataMap,
  children,
}: {
  config: Map<string, any>;
  phases: List<any>;
  tasks: List<any>;
  readOnly: boolean;
  components: Map<string, any>;
  configurations: Map<string, any>;
  deletedComponents: Map<string, any>;
  tablesMetadataMap: Map<string, any>;
  children: React.ReactNode;
}) => {
  const storeRef = useRef<BuilderStore>();

  const { nodes, edges } = useMemo(() => {
    return prepareFlowData(
      phases.toJS(),
      tasks.toJS(),
      components,
      configurations,
      deletedComponents,
    );
  }, [phases, tasks, components, configurations, deletedComponents]);

  const context = useMemo(
    () => ({ config, phases, tasks, readOnly, configurations, tablesMetadataMap }),
    [config, phases, tasks, readOnly, configurations, tablesMetadataMap],
  );

  // Update store only if nodes or edges have changed
  useLayoutEffect(() => {
    if (!storeRef.current) {
      return;
    }

    const currentNodes = storeRef.current.getState().nodes;
    const currentEdges = storeRef.current.getState().edges;

    let newNodes;
    const sameNodes = compareNodes(currentNodes, nodes);
    const sameEdges = compareEdges(currentEdges, edges);
    const isOnlyDataUpdated = onlyDataUpdated(currentNodes, nodes);

    if (!sameNodes) {
      if (isOnlyDataUpdated) {
        newNodes = currentNodes.map((node, index) => {
          return withDataIfAvailable(node, nodes[index]);
        });
      } else if (nodes.length < currentNodes.length) {
        const deletedNode = currentNodes.find((node) => !nodes.find((n) => n.id === node.id));
        const siblings = nodes.reduce<AppNode[]>((acc, node) => {
          if (
            currentEdges.find(
              (edge) => edge.source === node.id && edge.target === deletedNode?.id,
            ) ||
            currentEdges.find((edge) => edge.target === node.id && edge.source === deletedNode?.id)
          ) {
            acc.push(node);
          }
          return acc;
        }, []);
        newNodes = nodes.map((node) => {
          const found = currentNodes.find((n) => n.id === node.id);
          if (!found) {
            return node;
          }
          if (siblings.some((s) => s.id === node.id)) {
            return withDataIfAvailable(
              {
                ...found,
                width: found?.measured?.width,
                height: found?.measured?.height,
                measured: undefined,
              },
              node,
            );
          }
          if (
            node.position.x === 0 &&
            node.position.y === 0 &&
            node.data.tasks?.length === found.data.tasks?.length
          ) {
            return withDataIfAvailable(found, node);
          }
          return node;
        });
      } else {
        newNodes = nodes.map((node) => {
          const found = currentNodes.find((n) => n.id === node.id);
          if (found && node.data.tasks?.length !== found.data.tasks?.length) {
            return {
              ...node,
              width: found?.measured?.width,
              height: found?.measured?.height,
              hidden: true,
              style: { opacity: 0 },
            };
          }
          if (
            found &&
            node.position.x === 0 &&
            node.position.y === 0 &&
            node.data.tasks?.length === found.data.tasks?.length
          ) {
            return withDataIfAvailable(found, node);
          }
          return node;
        });
      }

      storeRef.current.getState().setNodes(newNodes);
    }

    if (!sameEdges) {
      // when only data is updated, graph will not be recalculated, so we have to show edges right away
      const newEdges = isOnlyDataUpdated
        ? edges.map((edge) => ({ ...edge, hidden: false }))
        : edges;

      storeRef.current.getState().setEdges(newEdges);
    }

    storeRef.current.getState().setContext(context);
  }, [nodes, edges, context]);

  // Create store only once
  if (!storeRef.current) {
    storeRef.current = createBuilderStore({ nodes, edges, context });
  }

  return <BuilderContext.Provider value={storeRef.current}>{children}</BuilderContext.Provider>;
};
