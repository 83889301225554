import { useState } from 'react';
import { useSortable } from '@dnd-kit/react/sortable';

import { Badge, ButtonInline, cn, Collapse, Icon, IconButton, Tooltip } from '@keboola/design';

import { InlineEdit } from '@/modules/flows-v2/builder/components/InlineEdit';
import { getDefaultOperand } from '@/modules/flows-v2/builder/helpers';
import type {
  AppNode,
  ConditionOperand,
  NextConditionWithId,
} from '@/modules/flows-v2/builder/types';
import { Condition } from './Condition';
import { GoToSelect } from './GoToSelect';

export const ConditionItem = ({
  id,
  index,
  nodes,
  parentIds,
  condition,
  readOnly,
  onDelete,
  onChange,
}: {
  id: string;
  index: number;
  nodes: AppNode[];
  parentIds: string[];
  condition: NextConditionWithId;
  readOnly: boolean;
  onDelete: () => void;
  onChange: (condition: NextConditionWithId) => void;
}) => {
  const { ref, handleRef } = useSortable({ id: condition.id, index, disabled: readOnly });
  const [open, setOpen] = useState(true);
  const operands = condition.condition?.operands ?? [];

  const updateName = (name: string) => {
    onChange({ ...condition, name });
  };

  const updateOperands = (operands: ConditionOperand[]) => {
    if (!condition.condition) {
      return;
    }

    onChange({ ...condition, condition: { ...condition.condition, operands } });
  };

  return (
    <div ref={ref}>
      <Collapse
        open={open}
        header={
          <div
            className={cn('tw-flex tw-items-center tw-gap-2 tw-min-h-10', {
              'tw-cursor-pointer': !readOnly,
            })}
            onClick={() => setOpen((prev) => !prev)}
          >
            {!readOnly && (
              <span
                ref={handleRef}
                className="tw-absolute -tw-left-[3px] tw-top-[17px] tw-cursor-move"
              >
                <Icon icon="grip-dots-vertical" className="tw-ml-1 tw-text-neutral-400" />
              </span>
            )}
            <Icon fixedWidth icon={open ? 'chevron-down' : 'chevron-right'} className="tw-ml-1" />

            <InlineEdit
              name={condition.name ?? 'Condition'}
              onChange={updateName}
              placeholder="Condition name"
              className="[&_.fa-pen]:tw-h-2.5 [&_.fa-pen]:tw-w-2.5 [&_.fa-pen]:tw-p-[3px]"
            />

            {!readOnly && (
              <Tooltip
                placement="top"
                tooltip="Delete condition"
                triggerClassName="tw-ml-auto tw-opacity-0 tw-transition-opacity group-hover/condition:tw-opacity-100"
              >
                <IconButton
                  icon="trash"
                  size="small"
                  variant="invisible"
                  onClick={(e) => {
                    e.stopPropagation();

                    onDelete();
                  }}
                />
              </Tooltip>
            )}
          </div>
        }
        className="tw-group/condition tw-relative tw-min-h-14 tw-border tw-border-solid tw-border-neutral-150 tw-bg-white tw-px-3 tw-py-[7px]"
      >
        <div className="tw-mb-1 tw-mt-2 tw-flex tw-flex-col tw-items-start tw-gap-3">
          {operands.map((operand, index) => {
            return (
              <Condition
                id={id}
                isFirst={index === 0}
                isLast={index === operands.length - 1}
                key={index}
                readOnly={readOnly}
                condition={operand}
                onChange={(newOperand) => {
                  const newOperands = operands.map((operand, i) => {
                    return i === index ? newOperand : operand;
                  });

                  updateOperands(newOperands);
                }}
                onDelete={() => {
                  const newOperands = operands.filter((_, i) => i !== index);

                  updateOperands(newOperands);
                }}
                parentIds={parentIds}
                nodes={nodes}
              />
            );
          })}
          {!readOnly && (
            <ButtonInline
              className="tw-mx-auto tw-text-xs"
              onClick={() => {
                const newOperands = [...operands, getDefaultOperand(id)];

                updateOperands(newOperands);
              }}
            >
              <Icon icon="plus" />
              Add AND rule
            </ButtonInline>
          )}
          <Badge
            variant="blue-inverse"
            className="tw-w-[50px] tw-text-xs tw-uppercase tw-leading-5"
          >
            Then
          </Badge>
          <div className="tw-w-full tw-pl-6">
            <GoToSelect
              id={id}
              nodes={nodes}
              readOnly={readOnly}
              parentIds={parentIds}
              condition={condition}
              onChange={(goto) => onChange({ ...condition, goto })}
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
};
