import { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Tooltip } from '@keboola/design';

import string from '@/utils/string';
import { RunActionLinkStatus } from './RunActionLinkStatus';

class RunActionLink extends Component {
  render() {
    const isDisabled =
      this.props.status === RunActionLinkStatus.RUNNING ||
      this.props.status === RunActionLinkStatus.DISABLED;

    return (
      <Tooltip
        type="explanatory"
        placement="top"
        tooltip={this.props.tooltip}
        forceHide={!isDisabled || !this.props.tooltip}
      >
        <Button
          block
          disabled={isDisabled}
          variant="primary"
          icon="circle-play"
          isLoading={this.props.status === RunActionLinkStatus.RUNNING}
          onClick={this.props.onClick}
        >
          {string.capitalize(this.props.label.toLowerCase())}
        </Button>
      </Tooltip>
    );
  }
}

RunActionLink.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  status: PropTypes.oneOf(Object.values(RunActionLinkStatus)),
};

RunActionLink.defaultProps = {
  label: 'Run',
  tooltip: 'Run',
  status: RunActionLinkStatus.READY,
};

export default RunActionLink;
