import { createContext, useContext } from 'react';
import type { Edge, EdgeChange, NodeChange } from '@xyflow/react';
import { applyEdgeChanges, applyNodeChanges } from '@xyflow/react';
import type { List, Map } from 'immutable';
import { createStore, useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useShallow } from 'zustand/shallow';

import type { AppNode } from '@/modules/flows-v2/builder/types';

type Context = {
  config: Map<string, any>;
  phases: List<any>;
  tasks: List<any>;
  configurations: Map<string, any>;
  tablesMetadataMap: Map<string, any>;
  readOnly: boolean;
};

type BuilderState = {
  nodes: AppNode[];
  edges: Edge[];
  context: Context;

  nodeDragging: boolean;

  selectedTaskId: string | null;
  selectedPhaseId: string | null;
  selectedAddButton: string | null;
  selectedConditionId: string | null;

  getNodes: () => AppNode[];
  setNodes: (nodes: AppNode[]) => void;

  setEdges: (edges: Edge[]) => void;
  getEdges: () => Edge[];

  setContext: (context: Context) => void;

  setSelectedTaskId: (taskId: string | null) => void;
  setSelectedPhaseId: (phaseId: string | null) => void;
  setSelectedAddButton: (addButtonId: string | null) => void;
  setSelectedConditionId: (conditionId: string | null) => void;

  setNodeDragging: (nodeDragging: boolean) => void;

  onNodesChange: (changes: NodeChange<AppNode>[]) => void;
  onEdgesChange: (changes: EdgeChange<Edge>[]) => void;
};

export type BuilderStore = ReturnType<typeof createBuilderStore>;

export const createBuilderStore = ({
  nodes,
  edges,
  context,
}: {
  nodes: AppNode[];
  edges: Edge[];
  context: { config: Map<string, any>; phases: List<any>; tasks: List<any> };
}) => {
  return createStore<BuilderState>()(
    devtools(
      (set, get) => ({
        nodes,
        edges,
        context,

        nodeDragging: false,

        selectedTaskId: null,
        selectedPhaseId: null,
        selectedAddButton: null,
        selectedConditionId: null,

        setNodes: (nodes: AppNode[]) => set({ nodes }),
        getNodes: () => get().nodes,

        setEdges: (edges: Edge[]) => set({ edges }),
        getEdges: () => get().edges,

        setContext: (context: Context) => set({ context }),

        setSelectedPhaseId: (phaseId: string | null) =>
          set({
            selectedPhaseId: phaseId,
            selectedTaskId: null,
            selectedAddButton: null,
            selectedConditionId: null,
          }),
        setSelectedTaskId: (taskId: string | null) =>
          set({
            selectedTaskId: taskId,
            selectedPhaseId: null,
            selectedAddButton: null,
            selectedConditionId: null,
          }),
        setSelectedAddButton: (phaseId: string | null) =>
          set({
            selectedAddButton: phaseId,
            ...(typeof phaseId === 'string' && {
              selectedPhaseId: null,
              selectedTaskId: null,
              selectedConditionId: null,
            }),
          }),
        setSelectedConditionId: (conditionId: string | null) =>
          set({
            selectedConditionId: conditionId,
            selectedPhaseId: null,
            selectedTaskId: null,
            selectedAddButton: null,
          }),

        setNodeDragging: (nodeDragging: boolean) => set({ nodeDragging }),

        onNodesChange: (changes: NodeChange<AppNode>[]) => {
          set({ nodes: applyNodeChanges(changes, get().nodes) });
        },
        onEdgesChange: (changes: EdgeChange<Edge>[]) => {
          set({ edges: applyEdgeChanges(changes, get().edges) });
        },
      }),
      { name: 'builder-store' },
    ),
  );
};

export const BuilderContext = createContext<BuilderStore | null>(null);

export const useBuilderStore = <T>(selector: (state: BuilderState) => T) => {
  const store = useContext(BuilderContext);

  if (!store) {
    throw new Error('Missing BuilderProvider in the tree.');
  }

  return useStore(store, useShallow(selector));
};
