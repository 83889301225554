import { useMemo } from 'react';

import { Badge, IconButton, TextInput, Tooltip } from '@keboola/design';

import type {
  AppNode,
  ConditionOperand,
  OperandValue,
  Operator,
} from '@/modules/flows-v2/builder/types';
import Select from '@/react/common/Select';
import { humanitazeExpiration } from '@/react/common/TimeoutControl/helpers';
import {
  DURATION_OPTIONS,
  JOB_OPTIONS,
  OUTPUT_TABLES_DEFAULT,
  PHASE_OPTIONS,
  STATUS_OPTIONS,
} from './constant';
import {
  getOperandsAndOperator,
  getSubjectType,
  getSubjectValue,
  prepareConstValueChange,
  prepareOperatorChange,
  prepareOperatorOptions,
  prepareSubjectChange,
  prepareSubjectOptions,
  prepareSubjectTypeChange,
} from './helpers';

export const Condition = ({
  id,
  nodes,
  parentIds,
  condition,
  onChange,
  readOnly,
  onDelete,
  isFirst,
  isLast,
}: {
  id: string;
  nodes: AppNode[];
  parentIds: string[];
  condition: ConditionOperand;
  onChange: (condition: ConditionOperand) => void;
  onDelete: () => void;
  readOnly: boolean;
  isFirst: boolean;
  isLast: boolean;
}) => {
  const handleSubjectChange = (value: string) => {
    return onChange(prepareSubjectChange(condition, value));
  };

  const handleSubjectTypeChange = (value: OperandValue) => {
    onChange(prepareSubjectTypeChange(condition, value));
  };

  const handleOperatorChange = (value: Operator) => {
    onChange(prepareOperatorChange(condition, value));
  };

  const handleConstValueChange = (value: string | number) => {
    onChange(prepareConstValueChange(condition, value));
  };

  const subjectOptions = useMemo(() => {
    return prepareSubjectOptions(nodes, parentIds, id);
  }, [nodes, parentIds, id]);

  const { operands, operator } = getOperandsAndOperator(condition);
  const subjectType = getSubjectType(operands);

  const renderValueInput = () => {
    if (subjectType === 'job.duration') {
      return (
        <Select
          allowCreate
          clearable={false}
          disabled={readOnly}
          value={operands[1].value}
          options={DURATION_OPTIONS}
          onChange={(value: string | number) => {
            handleConstValueChange(Number(value));
          }}
          isValidNewOption={(inputValue) => {
            const numberValue = Number(inputValue);

            return !isNaN(numberValue) && numberValue > 0;
          }}
          newOptionCreator={(inputValue) => {
            const value = Number(inputValue);
            const option = DURATION_OPTIONS.find((option) => option.value === value);

            return {
              label: option ? option.label : `${humanitazeExpiration(value)}`,
              value: inputValue,
            };
          }}
          singleValueRenderer={(props) => {
            const option = DURATION_OPTIONS.find((option) => option.value === props.data.value);

            return (
              <div style={props.getStyles('singleValue', props)}>
                {option?.label ?? humanitazeExpiration(Number(props.data.value))}
              </div>
            );
          }}
          noResultsText="Invalid duration"
        />
      );
    }

    if (subjectType === 'job.result.output.tables') {
      return (
        <TextInput
          min={0}
          type="number"
          variant="secondary"
          value={String(operands[1].value ?? OUTPUT_TABLES_DEFAULT)}
          onChange={(value) => {
            handleConstValueChange(Number(value || OUTPUT_TABLES_DEFAULT));
          }}
          disabled={readOnly}
        />
      );
    }

    return (
      <Select
        clearable={false}
        disabled={readOnly}
        value={operands[1].value}
        options={STATUS_OPTIONS}
        onChange={handleConstValueChange}
      />
    );
  };

  return (
    <div className="tw-group/condition-row tw-w-full">
      <div className="tw-mb-2 tw-flex tw-h-8 tw-items-center tw-justify-between tw-gap-2">
        <Badge variant="green-inverse" className="tw-w-[50px] tw-text-xs tw-uppercase tw-leading-5">
          {isFirst ? 'If' : 'And'}
        </Badge>
        {!readOnly && !(isLast && isFirst) && (
          <Tooltip
            placement="top"
            tooltip="Delete rule"
            triggerClassName="tw-opacity-0 tw-transition-opacity group-hover/condition-row:tw-opacity-100"
          >
            <IconButton
              icon="trash"
              size="small"
              variant="invisible"
              onClick={(e) => {
                e.stopPropagation();

                onDelete();
              }}
            />
          </Tooltip>
        )}
      </div>
      <div className="tw-w-full tw-pl-6">
        <Select
          clearable={false}
          disabled={readOnly}
          placeholder="Select condition subject"
          value={getSubjectValue(condition)}
          options={subjectOptions}
          onChange={handleSubjectChange}
        />
      </div>
      <div className="tw-mt-2 tw-grid tw-grid-cols-3 tw-gap-2 tw-pl-6">
        <Select
          clearable={false}
          disabled={readOnly}
          value={subjectType}
          options={operands[0]?.type === 'phase' ? PHASE_OPTIONS : JOB_OPTIONS}
          onChange={handleSubjectTypeChange}
        />
        <Select
          clearable={false}
          disabled={readOnly}
          value={operator}
          options={prepareOperatorOptions(subjectType)}
          onChange={handleOperatorChange}
        />
        {renderValueInput()}
      </div>
    </div>
  );
};
