import type { ComponentProps } from 'react';
import type { DragDropProvider } from '@dnd-kit/react';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import { Icon } from '@keboola/design';

import TaskIcon from '@/modules/flows/components/TaskIcon';
import {
  prepareGoTo,
  prepareNewPhase,
  preparePhasesAndTasksAfterDelete,
} from '@/modules/flows-v2/builder/helpers';
import type {
  AppNode,
  ConditionOperand,
  NextConditionWithId,
  Operand,
  OperandValue,
  Operator,
  SubjectOperand,
  TaskOperand,
  ValueOperand,
} from '@/modules/flows-v2/builder/types';
import { Truncated } from '@/react/common';
import {
  BASIC_OPERATOR_OPTIONS,
  DURATION_DEFAULT,
  OUTPUT_TABLES_DEFAULT,
  RELATIVE_OPERATOR_OPTIONS,
  STATUS_DEFAULT,
} from './constant';

export type DragEndEvent = Parameters<
  NonNullable<ComponentProps<typeof DragDropProvider>['onDragEnd']>
>[0];

export const getAllDescendantPhases = (
  phaseId: string,
  phases: List<any>,
  visited = new Set<string>(),
): Set<string> => {
  if (visited.has(phaseId)) return visited;
  visited.add(phaseId);

  const phase = phases.find((p) => p.get('id') === phaseId);
  if (!phase) return visited;

  const nextPhases = phase.get('next', List()).toJS();
  nextPhases.forEach((next: NextConditionWithId) => {
    if (next.goto) {
      getAllDescendantPhases(next.goto, phases, visited);
    }
  });

  return visited;
};

export const setPhaseConditions = (
  phaseId: string,
  phases: List<any>,
  conditions: NextConditionWithId[],
) => {
  // Get current phase's conditions to compare with new ones
  const currentConditions: NextConditionWithId[] =
    phases
      .find((phase) => phase.get('id') === phaseId, null, Map())
      .get('next', List())
      .toJS() || [];

  // Get current and new goto values
  const currentGotos = currentConditions.map((c) => c.goto).filter(Boolean) as string[];
  const newGotos = conditions.map((c) => c.goto).filter(Boolean) as string[];

  // Find phases that are no longer referenced in the new conditions
  const removedPhases = currentGotos.filter((goto) => !newGotos.includes(goto));

  // Start with updating the conditions
  let updatedPhases = phases.map((phase) => {
    if (phase.get('id') === phaseId) {
      const next = conditions.map((condition) => {
        return _.omit({ ...condition, goto: prepareGoTo(condition.goto) }, 'id');
      });

      return phase.set('next', fromJS(next));
    }

    return phase;
  }) as List<any>;

  // Handle removed phases
  if (removedPhases.length > 0) {
    // Get all descendant phases that need to be removed
    const allPhasesToRemove = new Set<string>();
    removedPhases.forEach((removedPhaseId: string) => {
      getAllDescendantPhases(removedPhaseId, phases).forEach((id) => allPhasesToRemove.add(id));
    });

    // Check which phases are still referenced by other phases
    const referencedPhases = new Set<string>();
    updatedPhases.forEach((phase) => {
      // Skip the current phase and phases being removed
      if (phase.get('id') === phaseId || allPhasesToRemove.has(phase.get('id'))) {
        return;
      }

      phase.get('next', List()).forEach((next: Map<string, any>) => {
        if (next.get('goto')) {
          referencedPhases.add(next.get('goto'));
        }
      });
    });

    // Remove phases that are not referenced anymore
    const phasesToDelete = Array.from(allPhasesToRemove).filter(
      (phaseToDelete) => !referencedPhases.has(phaseToDelete),
    );

    // Delete phases in reverse order (from descendants to ancestors)
    const sortedPhasesToDelete = phasesToDelete.sort((a, b) => {
      const aIsDescendant = getAllDescendantPhases(b, phases).has(a);
      const bIsDescendant = getAllDescendantPhases(a, phases).has(b);

      return aIsDescendant ? -1 : bIsDescendant ? 1 : 0;
    });

    for (const phaseToDelete of sortedPhasesToDelete) {
      const { newPhases } = preparePhasesAndTasksAfterDelete(phaseToDelete, updatedPhases, List());
      updatedPhases = newPhases;
    }
  }

  // Handle new phases
  conditions.forEach((condition) => {
    const phaseExist = updatedPhases.some((phase) => phase.get('id') === condition.goto);

    if (
      !phaseExist &&
      condition.goto &&
      !updatedPhases.some((phase) => phase.get('id') === condition.goto)
    ) {
      updatedPhases = updatedPhases.push(
        prepareNewPhase(prepareGoTo(condition.goto)!, condition.goto),
      );
    }
  });

  return updatedPhases;
};

export const prepareGoToOptions = (nodes: AppNode[], parentIds: string[], phaseId: string) => {
  return nodes
    .filter((node) => {
      return node.type === 'phase' && !parentIds.includes(node.id) && node.id !== phaseId;
    })
    .map((node) => ({ label: node.data.name, value: node.id }));
};

export const prepareSubjectOptions = (nodes: AppNode[], parentIds: string[], phaseId: string) => {
  return nodes
    .filter((node) => {
      return node.type === 'phase' && (parentIds.includes(node.id) || node.id === phaseId);
    })
    .map((phase) => {
      return {
        label: `${phase.data.name} Phase`,
        options: [
          {
            label: (
              <div className="tw-flex tw-items-center">
                <Icon icon="box" className="tw-mr-2 tw-text-sm tw-text-secondary-500" />
                <Truncated text={phase.data.name} />
              </div>
            ),
            name: phase.data.name,
            value: `phase:${phase.id}`,
          },
          {
            label: `All Tasks in ${phase.data.name}`,
            value: `all:${phase.id}`,
          },
          {
            label: `Any Task in ${phase.data.name}`,
            value: `any:${phase.id}`,
          },
          ...phase.data.tasks
            .filter((task) => task.variant === 'job' && !!task.configId)
            .map((task) => {
              if (task.variant !== 'job') {
                return null;
              }

              return {
                label: (
                  <div className="tw-flex tw-items-center">
                    <TaskIcon src={task.iconUrl} size={20} />
                    <Truncated text={task.name} />
                  </div>
                ),
                name: task.name,
                value: `task:${task.id}`,
              };
            })
            .filter(Boolean),
        ],
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const prepareOperatorOptions = (value: OperandValue) => {
  if (value === 'job.status') {
    return BASIC_OPERATOR_OPTIONS;
  }

  if (value === 'job.duration') {
    return RELATIVE_OPERATOR_OPTIONS;
  }

  return [...BASIC_OPERATOR_OPTIONS, ...RELATIVE_OPERATOR_OPTIONS];
};

export const reorderConditions = (conditions: NextConditionWithId[], event: DragEndEvent) => {
  const from = conditions.findIndex((condition) => condition.id === event.operation.source?.id);
  const to =
    event.operation.source && 'index' in event.operation.source
      ? (event.operation.source.index as number)
      : -1;

  if (event.operation.canceled || from === -1 || to === -1) {
    return;
  }

  const conditionToMove = conditions[from];

  // Create a new array without the item at `from`
  const conditionsWithoutMovedItem = [...conditions.slice(0, from), ...conditions.slice(from + 1)];

  // Insert the `conditionToMove` at index `to`
  return [
    ...conditionsWithoutMovedItem.slice(0, to),
    conditionToMove,
    ...conditionsWithoutMovedItem.slice(to),
  ];
};

export const getSubjectValue = (condition?: ConditionOperand) => {
  if (!condition) {
    return condition;
  }

  if ('phase' in condition) {
    return condition.operator === 'ALL_TASKS_IN_PHASE'
      ? `all:${condition.phase}`
      : `any:${condition.phase}`;
  }

  if (condition.operands[0].type === 'phase') {
    return `phase:${condition.operands[0].phase}`;
  }

  if (condition.operands[0].type === 'function') {
    return `task:${condition.operands[0].operands[0].task}`;
  }

  return `task:${condition.operands[0].task}`;
};

export const prepareSubjectChange = (
  condition: ConditionOperand,
  value: string,
): ConditionOperand => {
  const [type, id] = value.split(':');
  const { operands, operator } = getOperandsAndOperator(condition);
  const isPhase = type === 'phase';

  if (['task', 'phase'].includes(type)) {
    return {
      type: 'operator',
      operator: isPhase
        ? ['EQUALS', 'NOT_EQUALS'].includes(operator)
          ? operator
          : 'EQUALS'
        : operator,
      operands: [
        isPhase
          ? { type: 'phase', phase: id, value: 'job.status' }
          : { type: 'task', task: id, value: getSubjectType(operands) },
        { type: 'const', value: isPhase ? STATUS_DEFAULT : operands[1].value },
      ],
    };
  }

  if (['all', 'any'].includes(type)) {
    return {
      type: 'operator',
      operator: type === 'all' ? 'ALL_TASKS_IN_PHASE' : 'ANY_TASKS_IN_PHASE',
      phase: id,
      operands: [
        {
          type: 'operator',
          operator,
          operands: [
            { type: 'task', task: '*', value: getSubjectType(operands) },
            { type: 'const', value: operands[1].value },
          ],
        },
      ],
    };
  }

  return condition;
};

export const prepareSubjectTypeChange = (
  condition: ConditionOperand,
  value: OperandValue,
): ConditionOperand => {
  const isNestedPhase = condition && 'phase' in condition;

  const isStatus = value === 'job.status';
  const isOutputTables = value === 'job.result.output.tables';

  const operands = isNestedPhase ? condition.operands[0].operands : condition.operands;

  const valueOperand: ValueOperand = {
    type: 'const',
    value: isOutputTables ? OUTPUT_TABLES_DEFAULT : isStatus ? STATUS_DEFAULT : DURATION_DEFAULT,
  };
  const operator = isStatus ? 'EQUALS' : 'GREATER_THAN';

  let subjectOperand: SubjectOperand =
    'function' in operands[0] ? { ...operands[0].operands[0], value } : { ...operands[0], value };

  if (isOutputTables) {
    subjectOperand = {
      type: 'function',
      function: 'COUNT',
      operands: [subjectOperand as TaskOperand],
    };
  }

  if (isNestedPhase) {
    return {
      ...condition,
      operands: [{ type: 'operator', operator, operands: [subjectOperand, valueOperand] }],
    };
  }

  return { ...condition, operator, operands: [subjectOperand, valueOperand] };
};

export const prepareOperatorChange = (
  condition: ConditionOperand,
  operator: Operator,
): ConditionOperand => {
  const isNestedPhase = condition && 'phase' in condition;

  if (isNestedPhase) {
    return { ...condition, operands: [{ ...condition.operands[0], operator }] };
  }

  return { ...condition, operator };
};

export const prepareConstValueChange = (
  condition: ConditionOperand,
  value: string | number,
): ConditionOperand => {
  const isNestedPhase = condition && 'phase' in condition;

  if (isNestedPhase) {
    return {
      ...condition,
      operands: [
        {
          ...condition.operands[0],
          operands: [condition.operands[0].operands[0], { type: 'const', value }],
        },
      ],
    };
  }

  return { ...condition, operands: [condition.operands[0], { type: 'const', value }] };
};

export const getOperandsAndOperator = (condition: ConditionOperand) => {
  const isNestedPhase = condition && 'phase' in condition;
  const { operands, operator } = isNestedPhase ? condition.operands[0] : condition;

  return { operands, operator };
};

export const getSubjectType = (operands: Operand['operands']) => {
  if ('function' in operands[0]) {
    return operands[0].operands[0].value;
  }

  return operands[0].value;
};
